<template>
    <div class="app-wrapper">
        <Header/>
        <app-main/>
        <Footer class="footer"></Footer>
    </div>
</template>

<script>
    import Header from "./components/Header";
    import AppMain from "./components/AppMain";
    import Footer from "./components/Footer";
    export default {
        name: "index",
        components:{Footer, Header,AppMain}
    }
</script>

<style scoped lang="less">
    .app-wrapper{
        margin: 0 auto;
        background: url('../assets/images/image-bg.png') no-repeat center center;
        min-width: 1070px;
        // height: 1920px;
        height: 100vh;
        max-width: 100vw;
        position: relative;
        *{
            box-sizing: border-box;
        }
        .footer{
            position: absolute;
            top: calc(1920px - 68px);
        }
    }
    
</style>
