<template>
    <div class="user-info display-flex-between">
    <div @click="goHome()" class="info display-flex-left">
        <img class="image72 mr20" :src="isLogin? (userinfo.avatar_url || defaultAvatar) :defaultAvatar" alt="">
        <div class="f20 cor-gray43">
            <div class="f24 fw-400">{{isLogin? (userinfo.realname || userinfo.username ||'') : '游客用户'}}</div>
            <span class="role mt5 " v-if="identity && identity.type_str">{{identity?identity.type_str||'':''}}</span>
        </div>
    </div>
    <div class="exit display-flex-center" v-if="isLogin" @click="loginOut()">
        <img class="image32 mr6" src="../../assets/images/icon-exit.png" alt="">
        <div class="f32 cor-redff">退出</div>
    </div>
    <div class="login display-flex-center" v-else @click="loginIn()">
        <img class="image32 mr6" src="../../assets/images/icon-login.png" alt="">
        <div class="f32">登录</div>
    </div>
</div>
</template>

<script>
    let vm;
    // import {Debounce} from "../../utils/util";
    import util from '../../utils/localStorage'
    import {getToken, removeToken} from "../../utils/cookieToken";
    import { Loading } from 'element-ui';

    export default {
        name: "LoginInfo",
        data(){
            return {
                defaultAvatar:require('../../assets/images/default-avatar.png'),
                userinfo:util.localStorage_get('co_web_userinfo','json'),
                identity:this.$store.state.app.identity,
                isJoin :null,
                isLogin:getToken() ? true : false,
                options:{
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.3)'
                }
            }
        },
       
        mounted(){
            vm = this;
            vm.getVillageDetail();
            console.log(this.userinfo)
        },
        computed: {
            getIdentity() {
                return this.$store.state.app.identity
            },
        },
        watch: {
            getIdentity() {
                vm.identity = vm.$store.state.app.identity;
                vm.$forceUpdate();
            },
        },
        methods:{
            goHome(){
                this.$router.push({name: 'Home'})

            },
            loginOut(){
                removeToken();
                vm.userinfo = null;
                vm.$store.commit('app/del_userinfo','userinfo');
                vm.$store.commit('app/del_token','token');
                this.isLogin = false
                this.$store.state.app.identity = null;
                this.identity = null
                vm.$router.push({name:"Login"})
            },
            loginIn(){
                vm.$router.push({name:"Login"})
            },
            getVillageDetail(){
                // if(!vm.isLogin && this.$route.name !== 'Welcome' && this.$route.name !== 'Register'
                // && this.$route.name !== 'Home' && this.$route.name !== 'Rank'
                // && this.$route.name !== 'ShareLine' && this.$route.name !== 'Declaration'
                // && this.$route.name !== 'DeclarationSort' && this.$route.name !== 'DeclarationDetail'){
                //     vm.$router.push({name:'Login'});
                //     return false;
                // }
                Loading.service(this.options);
                vm.$store.dispatch("app/getVillageDetail").then(res => {
                    Loading.service(this.options).close();
                    vm.isJoin = vm.$store.state.app.isJoin;
                    vm.identity = res.identity;
                    // console.log(1234,vm.isJoin)
                    if(vm.isLogin && !vm.isJoin){
                        vm.$message({
                            message: '您还未加入当前社区,请先加入社区哦！',
                            type: 'error'
                        });
                        vm.$router.push({name:'Join'})
                    }



                })
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/css/index";
    @import "../../assets/css/variables";
    .user-info{
        position: relative;
        width:880px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 32px 32px 32px 32px;
        margin: 30px auto auto;
        padding: 0 20px;
        .info{
            .role{
                height: 36px;
                padding: 0 12px;
                line-height: 36px;
                display: inline-block;
                background: #FE9031;
                color: #fff;
                border-radius: 18px 18px 18px 18px;
                flex-shrink: 0 !important;
            }
        }
        .exit,.login{
            cursor: pointer;
            width: 134px;
            height: 58px;
            line-height: 58px;
            background: #FFE4E4;
            border-radius: 16px 16px 16px 16px;
        }
        .login{
            background: #E3F8F0;
            color: #49D6B0 !important;
        }
    }
</style>
