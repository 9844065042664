import axios from 'axios'
// 根据环境不同引入不同api地址
import {baseApi} from '@/config'
import {getToken, removeToken} from "./cookieToken";
import router from '../router'
import {Message, Loading} from 'element-ui';
import store from "../store";
import localStorage from "../utils/localStorage"
// create an axios instance
const service = axios.create({
    baseURL: baseApi, // url = base api url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
});
let options = {
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
};

// request拦截器 request interceptor
service.interceptors.request.use(
    config => {
        console.log("baseApi",baseApi)
        // 不传递默认开启loading
        if (!config.hideloading) {
            // Loading.service(options);
        }
        if (getToken()) {
            config.headers['Authorization'] = 'Bearer ' + getToken();
        }

        config.headers['Village-Sign'] = localStorage.localStorage_get("VillageSign");
        // config.headers['Village-Sign'] = 'visitor';
        config.headers['Sign-Token'] = '$2y$10$GdTzeowLdtqoDjB7EBmv/O23FkslgixoOIZfHSBIm9ooEiDF.YGn2';

        return config
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error)
    }
);
// respone拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        Loading.service(options);
        if (res.code && res.code !== 200) {
            // 登录超时,重新登录
            if (res.code === -9999) {
                Message({
                    message: '请登录',
                });
                store.commit('user/del_userinfo');
                removeToken();
                // router.push("/login")
            }

            if (res.code === -1 || res.code === -10000) {
                Message({
                    message: res.data|| res.msg,
                });
            }
            Loading.service(options).close();
            return Promise.reject(res || 'error')
        } else {
            Loading.service(options).close();
            return Promise.resolve(res)

        }
    },
    error => {
        Loading.service(options).close();
        console.log('err' + error); // for debug
        return Promise.reject(error)
    }
);

export default service
