// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/image-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".app-wrapper[data-v-76ccda65]{margin:0 auto;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;min-width:1070px;height:100vh;max-width:100vw;position:relative}.app-wrapper *[data-v-76ccda65]{box-sizing:border-box}.app-wrapper .footer[data-v-76ccda65]{position:absolute;top:1852px}", ""]);
// Exports
module.exports = exports;
