<template>
    <div class="header-container">
        <div class="top-nav display-flex-between">
            <div class="logo display-flex-left">
                <!-- <img class="image100 mr20" :src="village? village.villageLogo || localVillage.villageLogo: localVillage.villageLogo" alt="">
                <div class="f36 cor-gray43">{{village? village.villageName||localVillage.villageName: localVillage.villageName}}便民积分系统</div> -->
                <img class="image100 mr20" :src="villageLogo" alt="">
                <div class="f36 cor-gray43">{{villageName}}便民积分系统</div>
            </div>
            <div class="date-time cor-gray43 display-flex-left">
                <div class="date mr16">
                    <div class="week f24 align-right">
                        {{$moment().format('dddd')}}
                    </div>
                    <div class="day f15 mt10">
                        {{$moment().format('LL')}}
                    </div>
                </div>
                <div class="time f54">
                    {{time}}
                </div>
            </div>
        </div>
        <div class="cor-border">
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import localStorage from "../../utils/localStorage";
import {sendCode, login,villagesImage} from "../../api/common";

export default {
    name: "Header",
    
    data() {
        return {
            time: this.$moment().format('LT'),
            timer: null,
            login: this.$store.state.app.token ? true : false,
            villageName:'',
            villageLogo:'',
            localVillage:localStorage.localStorage_get('co_web_village')|| {}
        }
    },
    created(){
        const _this = this;
        _this.timer = setInterval(() => {
            _this.time = _this.$moment().format('LT')
        }, 1000)
        let VillageSign = localStorage.localStorage_get('VillageSign');
        if(!VillageSign){
            localStorage.localStorage_setString('VillageSign',null)
            // localStorage.localStorage_setString('VillageSign','visitor')
        }
    },
    mounted(){
        this.getVillageImage();
    },
    computed: {
        ...mapGetters(["village"]),
        monitor() {
            return this.$store.state.app.token
        }
    },
    watch: {
        monitor() {
            this.login = this.$store.state.app.token ? true : false;
            // console.log(this.login)
            this.$forceUpdate();
        }
    },
    methods: {
        getVillageImage(){
            const _this = this;
            villagesImage().then(res=>{
                let village_sign = localStorage.localStorage_get('VillageSign');
                // console.log('village_sign',village_sign)
                res.data.map(obj=>{
                    if(obj.sign === village_sign){
                        _this.villageLogo = obj.logo_url;
                        _this.villageName = obj.title;
                        _this.$store.commit("app/set_village", {villageLogo: _this.villageLogo,
                            villageName: _this.villageName, id: obj.id})
                    }
                })
            })
        },
    },
    beforeDestroy() {
        if(this.timer){
            this.timer = null
        }
    },
}
</script>

<style scoped lang="less">
.header-container{
    height: 126px;
    .top-nav{
        height: 126px;
        padding: 0 32px;
        background: #fff;
    }
    .cor-border{
        width:100%;
        height: 11px;
        background: linear-gradient(90deg, #FFCF92 0%, #FF6060 100%);
    }
   
}
</style>
