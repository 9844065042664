<template>
    <div class="main-container">
        <div class="inner-container">
            <login-info v-if="infoShow"/>
            <div v-else class="seat"></div>
            <transition name="fade-transform" mode="out-in">
                <router-view/>
            </transition>
        </div>

    </div>
</template>

<script>
    import {Debounce} from "../../utils/util";
    import LoginInfo from "./LoginInfo";
    import {getToken, removeToken} from "../../utils/cookieToken";


    let vm;

    export default {
        name: "AppMain",
        components: {LoginInfo},
        data() {
            return {
                login: getToken() ? true : false,
                infoShow: true,
            }
        },
        mounted() {
            vm = this;
            // console.log(this.$route.name ,this.$route.name === 'Welcome')
            if(this.$route.name === 'Welcome'
                || this.$route.name === 'Login' 
                || this.$route.name === 'Register'
                || this.$route.name === 'Join'){
                this.infoShow = false;
            }else{
                this.infoShow = true;
            }
            
        },
        computed: {
            monitor() {
                return this.$store.state.app.token
            }
        },
        watch: {
            monitor() {
                vm.login = getToken() ? true : false;
                vm.$forceUpdate();
            },
            $route(to,from){
                // console.log(to,from)
                if(to.name === 'Welcome'
                || to.name === 'Login' 
                || to.name === 'Register'
                || to.name === 'Join'){
                    this.infoShow = false;
                }else{
                    this.infoShow = true;
                }
                // if(!vm.login && to.name !== 'Welcome' && to.name !== 'Register'
                // && to.name !== 'Login'
                // && to.name !== 'Home' && to.name !== 'Rank'
                // && to.name !== 'ShareLine' && to.name !== 'Declaration'
                // && to.name !== 'DeclarationSort' && to.name !== 'DeclarationDetail'){
                //     this.$message.info("请先登录")
                //     vm.$router.push({name:'Login'});
                //     return false;
                // }
                if(!vm.login &&( to.name == 'Account' ||to.name == 'MyExchange' ||
                to.name == 'MyExchangeDetail' ||to.name == 'MyTask' ||
                to.name == 'Verification' ||to.name == 'Userinfo' ||
                to.name == 'MyFamily' ||to.name == 'JoinVillage' ) ){
                    this.$message.info("请先登录")
                    vm.$router.push({name:'Login'});
                    return false;
                }
            }
        },
    }
</script>

<style scoped lang="less">
    @import "../../assets/css/variables";
    @import "../../assets/css/index";

    .main-container {
        .seat{
            height: 130px;
        }
        // width: 100%;
        // background-image: url("../../assets/images/main-bg.png");
        // background-size: 100% 100%;
        // min-height: calc(100vh - 48px);
        // //height: 100vh;
        // min-width: 1200px;
        // padding: 80px 0 0;
        // box-sizing: border-box;
        // @media screen and ( max-width: 1080px ) {
        //     min-height: calc(100vh - 48px);
        //     //height: 100vh;
        //     padding: 140px 0 0;
        //     min-width: 952px;
        //     .inner-container {
        //         width: 952px;
        //     }
        // }

        // .inner-container {
        //     width: 1200px;
        //     margin: auto;
        //     @media screen and ( max-width: 1080px ) {
        //         width: 952px;
        //     }

        // }
    }


</style>
