<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import {getToken, removeToken} from "./utils/cookieToken";
    import localStorage from "./utils/localStorage";

    export default {
        name: "App",
        data() {
            return {
                _beforeUnload_time:'',
                _gap_time:'',
            }
        },
        // mounted(){
        //     // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的登录数据
        //     // window.onbeforeunload = function (e) {
        //         // removeToken();
        //         // vm.$store.commit('app/del_userinfo','userinfo');
        //         // vm.$store.commit('app/del_token','token');
        //     // }
        // },
        mounted() {
            //console.log(localStorage.localStorage_get('VillageSign'))
            window.addEventListener('beforeunload', (e) => {
                this._beforeUnload_time = new Date().getTime()
                console.log(this._beforeUnload_time, 'this._beforeUnload_time')
            })
            window.addEventListener('unload', () => {
                this._gap_time = new Date().getTime() - this._beforeUnload_time
                console.log(this._gap_time, 'ssss', new Date().getTime(), this._beforeUnload_time)
                if (this._gap_time <= 5) {
                    //关闭
                    // localStorage.removeItem('token')
                    // localStorage.localStorage_setString('VillageSign','1234')
                    removeToken();
                    vm.$store.commit('app/del_userinfo','userinfo');
                    vm.$store.commit('app/del_token','token');
                } else {
                    // 刷新
                    // localStorage.localStorage_setString('VillageSign','1234')
                    // removeToken();
                    // vm.$store.commit('app/del_userinfo','userinfo');
                    // vm.$store.commit('app/del_token','token');
                }
            })
        },
        destroyed() {
            window.removeEventListener('beforeunload')
            window.removeEventListener('unload')

        },

    }
</script>

<style lang="less">
    [v-cloak] {
        display: none;
    }

    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
    }
    *{
        box-sizing: border-box;
    }
    a{text-decoration: none;}
    .description-box{
        width: 100%;
        img {
            width: 100%;
        }
    }
</style>
