import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/index.less'
import localStorage from "./utils/localStorage";

import moment from "moment";  
moment.locale('zh-cn');
import QRCode from "qrcode"
Vue.use(QRCode)
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.villageSign = localStorage.localStorage_get('villageSign');
import Print from 'vue-print-nb'
//富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import md5 from 'js-md5';
Vue.use(Print)
Vue.prototype.$empty = require('./assets/images/empty.png');
Vue.prototype.$moment = moment;
Vue.prototype.$QRCode = QRCode;
Vue.prototype.$md5 = md5;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
