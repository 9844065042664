import Layout from '@/layout'

export const constantRouterMap = [
    // {
    //     path: '/',
    //     component: Layout,
    //     children: [
    //         {
    //             path: '/',
    //             name: 'Home',
    //             component: () => import('@/views/Home'),
    //             meta: {title: '首页', keepAlive: false}
    //         }
    //     ]
    // },
    {
        path: '/',
        redirect: 'welcome',
        component: Layout,
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/views/Home'),
                meta: {title: '首页', keepAlive: false}
            }
        ]
    },

    {
        path: '/welcome',
        component: Layout,
        children: [
            {
                path: '/welcome',
                name: 'Welcome',
                component: () => import('@/views/welcome'),
                meta: {title: '欢迎页', keepAlive: false}
            }
        ]
    },
    {
        path: '/login',
        component: Layout,
        children: [
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/login'),
                meta: {title: '登录', keepAlive: false}
            }
        ]
    },
    {
        path: '/register',
        component: Layout,
        children: [
            {
                path: '/register',
                name: 'Register',
                component: () => import('@/views/register'),
                meta: {title: '登录', keepAlive: false}
            }
        ]
    },
    {
        path: '/join',
        component: Layout,
        children: [
            {
                path: '/join',
                name: 'Join',
                component: () => import('@/views/join/index'),
                meta: {title: '加入社区', keepAlive: false}
            }
        ]
    }, 
    {
        path: '/fastJoin',
        component: Layout,
        children: [
            {
                path: '/fastJoin',
                name: 'FastJoin',
                component: () => import('@/views/join/fast'),
                meta: {title: '加入社区', keepAlive: false}
            }
        ]
    }, 
       {
        path: '/rank',
        component: Layout,
        children: [
            {
                path: '/rank',
                name: 'Rank',
                component: () => import('@/views/rank/rank'),
                meta: {title: '荣誉榜单', keepAlive: false}
            }
        ]
    },
    {
        path: '/taskHall',
        component: Layout,
        meta: {title: '任务大厅', keepAlive: false},
        children: [
            {
                path: '/taskHall',
                name: 'TaskHall',
                component: () => import('@/views/task/taskHall'),
                meta: {title: '任务大厅', keepAlive: false}
            },{
                path: '/taskDetails',
                name: 'TaskDetails',
                component: () => import('@/views/task/taskDetails'),
                meta: {title: '任务详情', keepAlive: false}
            },{
                path: '/taskReport',
                name: 'TaskReport',
                component: () => import('@/views/task/taskReport'),
                meta: {title: '任务简报', keepAlive: false}
            },{
                path: '/taskReportDetail',
                name: 'TaskReportDetail',
                component: () => import('@/views/task/taskReportDetail'),
                meta: {title: '简报详情', keepAlive: false}
            },{
                path: '/shareLine',
                name: 'ShareLine',
                component: () => import('@/views/task/shareLine'),
                meta: {title: '友邻圈', keepAlive: false}
            }
        ]
    },
    {
        path: '/declaration',
        component: Layout,
        meta: {title: '申报公示', keepAlive: false},
        children: [
            {
                path: '/declaration',
                name: 'Declaration',
                component: () => import('@/views/declaration/index'),
                meta: {title: '申报公示栏', keepAlive: false}
            },
            {
                path: '/declarationSort',
                name: 'DeclarationSort',
                component: () => import('@/views/declaration/declarationSort'),
                meta: {title: '申报公示栏', keepAlive: false}
            },
            {
                path: '/declarationDetail',
                name: 'DeclarationDetail',
                component: () => import('@/views/declaration/declarationDetail'),
                meta: {title: '申报详情', keepAlive: false}
            }
        ]
    },
    {
        path: '/mall',
        component: Layout,
        meta: {title: '申报公示', keepAlive: false},
        children: [
            {
                path: '/mall',
                name: 'Mall',
                component: () => import('@/views/mall/index'),
                meta: {title: '积分兑换', keepAlive: false}
            },
            {
                path: '/productDetail',
                name: 'ProductDetail',
                component: () => import('@/views/mall/productDetail'),
                meta: {title: '商品详情', keepAlive: false}
            },
            {
                path: '/exchangeDetail',
                name: 'ExchangeDetail',
                component: () => import('@/views/mall/exchangeDetail'),
                meta: {title: '确认订单', keepAlive: false}
            },
            {
                path: '/orderDetail',
                name: 'OrderDetail',
                component: () => import('@/views/mall/orderDetail'),
                meta: {title: '订单详情', keepAlive: false}
            },
            {
                path: '/verification',
                name: 'Verification',
                component: () => import('@/views/mall/verification'),
                meta: {title: '兑换核销', keepAlive: false}
            },
            {
                path: '/verificationScan',
                name: 'VerificationScan',
                component: () => import('@/views/mall/verificationScan'),
                meta: {title: '扫码核销', keepAlive: false}
            },
            {
                path: '/verificationCode',
                name: 'VerificationCode',
                component: () => import('@/views/mall/verificationCode'),
                meta: {title: '核销码核销', keepAlive: false}
            },
            {
                path: '/verificationDetail',
                name: 'VerificationDetail',
                component: () => import('@/views/mall/verificationDetail'),
                meta: {title: '核销订单详情', keepAlive: false}
            }
        ]
    },
    {
        path: '/exchange',
        component: Layout,
        meta: {title: '积分兑换', keepAlive: false},
        children:[
            {
                path: '/exchange',
                name: 'Exchange',
                component: () => import('@/views/integral/exchange'),
                meta: {title: '积分兑换', keepAlive: false}
            },{
                path: '/productDetails',
                name: 'ProductDetails',
                component: () => import('@/views/integral/productDetails'),
                meta: {title: '商品详情', keepAlive: false}
            },{
                path: '/orderDetails',
                name: 'OrderDetails',
                component: () => import('@/views/integral/orderDetails'),
                meta: {title: '订单详情', keepAlive: false}
            }
        ]
    },
    {
        path: '/success',
        component: Layout,
        children:[
            {
                path: '/success',
                name: 'Success',
                component: () => import('@/views/status/success'),
                meta: {title: '成功', keepAlive: false}
            },
        ]
    },
    {
        path: '/index',
        component: Layout,
        meta: {title: '我的菜单', keepAlive: false},
        children:[
            {
                path: '/index',
                name: 'Index',
                component: () => import('@/views/me/index'),
                meta: {title: '我的菜单', keepAlive: false}
            },{
                path: '/account',
                name: 'Account',
                component: () => import('@/views/me/account'),
                meta: {title: '我的账户', keepAlive: false}
            },{
                path: '/myExchange',
                name: 'MyExchange',
                component: () => import('@/views/me/myExchange'),
                meta: {title: '我的兑换', keepAlive: false}
            },{
                path: '/myExchangeDetail',
                name: 'MyExchangeDetail',
                component: () => import('@/views/me/myExchangeDetail'),
                meta: {title: '兑换详情', keepAlive: false}
            },{
                path: '/myTask',
                name: 'MyTask',
                component: () => import('@/views/me/myTask'),
                meta: {title: '我的任务', keepAlive: false}
            },{
                path: '/verification',
                name: 'Verification',
                component: () => import('@/views/me/verification'),
                meta: {title: '兑换核销', keepAlive: false}
            },{
                path: '/userinfo',
                name: 'Userinfo',
                component: () => import('@/views/me/userinfo'),
                meta: {title: '个人信息', keepAlive: false}
            },{
                path: '/myFamily',
                name: 'MyFamily',
                component: () => import('@/views/me/myFamily'),
                meta: {title: '我的家庭', keepAlive: false}
            },{
                path: '/joinVillage',
                name: 'JoinVillage',
                component: () => import('@/views/me/joinVillage'),
                meta: {title: '加入社区', keepAlive: false}
            },
        ]
    }

];
