export default {
    localStorage_set(key, value, expired) {
        /*
           * set 存储方法
           * @ param {String} 	key 键
           * @ param {String} 	value 值，
           * @ param {String} 	expired 过期时间，以天为单位，非必须
           */

        localStorage[key] = JSON.stringify(value);
        if (expired) {
            localStorage[`${key}__expires__`] = Date.now() + 1000 * 60 * 60 * 24 * expired;
        }
        return value;

    },
    localStorage_setString(key, value, expired) {
        /*
           * set 存储方法
           * @ param {String} 	key 键
           * @ param {String} 	value 值，
           * @ param {String} 	expired 过期时间，以天为单位，非必须
           */

        localStorage[key] = value;
        if (expired) {
            localStorage[`${key}__expires__`] = Date.now() + 1000 * 60 * 60 * 24 * expired;
        }
        return value;

    },

    localStorage_get(key,type) {
        /*
          * get 获取方法
          * @ param {String} 	key 键
          * @ param {String} 	expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
          */
        const expired = localStorage[`${key}__expires__`] || Date.now + 1;
        const now = Date.now();

        if (now >= expired) {
            localStorage.removeItem(key);
            return;
        }

        if(type === 'json' && localStorage[key]){
            return JSON.parse(localStorage[key])
        }else{
            return localStorage[key];
        }


    },

    localStorage_del(key) {
        const value = localStorage[key];
        delete localStorage[key];
        delete localStorage[`${key}__expires__`];
        return value;

    },

}
