/**
 * 防抖:将多次执行变为最后一次执行。
 * */
export const Debounce = (fn, t) => {
    let delay = t || 500;
    let timer;
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, args);
        }, delay);
    };
};

/**
 * 节流：将多次执行变成每隔一段时间执行。
 * */
export const Throttle = (fn, t) => {
    let delay = t || 500;
    let timer = null;
    let timeStamp = new Date();
    return function () {
        let context = this;  //获取函数所在作用域this
        let args = arguments;  //取得传入参数
        if (new Date() - timeStamp > delay) {
            timeStamp = new Date();
            timer = setTimeout(function () {
                fn.apply(context, args);
                timer = null;
            }, delay);
        }

    }
};

/*
* 是否是竖屏 （小于1080px）
* */
export function isVerticalScreen() {
    let vs = false;
    if (window.screen.width <= 1080) {
        vs = true
    }
    return vs;
}

/**
 * 对象去重
 * console.log(ssff(arr, 'id'))
 * */
export function ssff(arr, id) {
    let obj = {};

    function deWeight() {
        arr = arr4.reduce(function (a, b) {
            obj[b.id] ? '' : obj[b.id] = a.push(b);
            return a;
        }, []);
        return arr4;
    }

    return deWeight();
}

/**
 * 实现一个类型判断
 * */
export function type_of(obj) {
    let res = Object.prototype.toString.call(obj).split(' ')[1];// 输出 RegExp]
    /*
    res.substring(0, res.length - 1) 从第 0 项开始截取 截取到它的长度 -1
    也就是最后一项的前一项 把最后的一项  ] 给干掉
    */
    return res.substring(0, res.length - 1).toLowerCase();
}

/**
 * 生成随机数
 * */
export function random(min, max) {
    //  Math.floor() 向下进行取证
    return Math.floor(Math.random() * (max - min + 1) + min)
}
