<template>
    <div class="footer-container">
        版权所有@北京扬世正雅文化艺术传播有限公司
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="less">
.footer-container{
    width: 100%;
    // min-width: 1080px;
    height: 48px;
    line-height: 48px;
    color: 4A4747;
    text-align: center;
    font-size: 20px;
    // background-color: #4A4747;
}
</style>
