import Cookies from 'js-cookie'
import router from '../router'
const TokenKey = 'co-creator-web_token';

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}


//创建有效期为7天的cookie
export function setToken_day(token,day){
    return Cookies.set(TokenKey, token, { expires: day || 7 });
}
