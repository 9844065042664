const api = {
    login: 'auth/login/phone',  //登录
    idcardLogin: 'auth/idcard-login',  //身份证登录
    chooseLogin: 'auth/choose-login',//选择登录
    removeAccount: 'auth/remove-account',//删除账号
    register: 'auth/register',  //注册
    send_ode: 'common/code',   //获取验证码
    villages: 'village',  //社区详情
    join: 'terminal/apply',   //加入社区
    villages_image: 'villages',   //社区登录图片
    upload: '/common/upload',    //上传附件
    museum: 'thing-index',//先进事迹
    
    task_list: 'task',	//任务列表
    user_task: 'user/task',	//我的任务
    categories: 'categories',  //任务分类标签
    task_tags_list: 'task/tag',		//任务分类列表
    detail: "task/show",		//任务详情
    apply: 'task/enter',	//报名
    task_sign: 'task/sign',
    post_list: 'post',  //友邻圈列表
    products: 'common/products',   //产品列表
    products_index: 'common/products/score/index',  //商城首页
    products_details: 'common/products/',
    village_list: 'common/cfg',	//社区列表
    branch: "branch",	//党支部

    open: 'families',		//开通家庭
    family_integral: 'families/',		//家庭积分详情
    stream: 'user/stream',   //用户积分明细
    orders: 'orders',	//兑换下单
    order_details: 'orders/',	//订单详情
    order_valid: 'orders/business/index',  //核销查询
    usage: 'orders/usage/',		//核销

    villageParkList: 'user/village/',//社区园区列表
    roleList: 'customer/role',//角色列表
    roleDetail: 'customer/role/',//角色详情
    applyJoin: 'village/join',//申请加入
    joinQuick: 'village/quick-residential',   //加入社区


    familyRankM: 'family-rank',//申请加入
    rank: 'village/rank',		//社区排行



    //积分申报接口
	declaration:{
		li:'declaration/list',//所有申报列表
		list:'declaration',//所有申报列表
		listMine:'declaration/me',//我的申报
		detail:'declaration/show/',//申报详情
		listPub:'declaration/list',//公示列表
		categoryList:'declaration/category',//申报分类列表
		edit:'declaration',//发起申报
		conditionList:'declaration/filter', //申报条件列表
		cancel:'declaration/cancel/',//撤销申报
		status:'user/apply'//公示栏显示状态
	},
    // 商城
	mall:{
		index:'common/products/score/index',  //首页数据
		details:'common/products/',
		orders:'orders',	//兑换下单
		orderValid:'orders/business/index' ,
		usage:'orders/usage/',		//核销
		usageConvert:'orders/usage-convert',		//核销
		products:'common/products',   //产品列表
		adBrand: 'ad-brand',//
		bannerProducts: 'banner-products', //banner产品
		recommendProducts:'common/products/recommend/products', //推荐产品
		specialProducts:'common/products/specialness/products', //推荐产品
		storeList: 'organs/select', //商家列表
		// storeProductList: 'common/products/category/products', //商家产品列表
		storeProductList: 'organs/products', //商家产品列表
		categoryList:'common/products/category/list', //产品分类列表
		productList:'common/products/category/products', //产品分类列表
	},

};
export default api;
