import util from '../../utils/localStorage'
import {villageDetails} from "../../api/common";
import {getToken,setToken_day,removeToken} from "../../utils/cookieToken";

const state = {
    token:getToken(),
    userinfo:null,
    isFamily:false,  //是否加入家庭
    identity:null,   //在当前社区的身份信息
    familyId:null,
    villageId:null,  //社区id
    village:null,
    isJoin:false,   //是否加入社区
};

const mutations = {
    set_token:(state, token)=>{
        state.token = token;
        setToken_day(token)
    },
    set_token:(state, token)=>{
        state.token = token;
        setToken_day(token)
    },
    set_token:(state, token)=>{
        state.token = token;
        setToken_day(token)
    },

    del_token:(state)=>{
        state.token = null;
        removeToken()
    },

    set_userinfo: (state, userinfo) => {
        state.userinfo = userinfo;
        util.localStorage_set('co_web_userinfo',userinfo,7);
    },
    del_userinfo: (state) => {
        state.userinfo = null;
        util.localStorage_del('co_web_userinfo');
    },

    set_identity(state, identity) {
        state.identity = identity;
        console.log(identity)

    },

    set_village(state, village) {
        state.village = village;
        util.localStorage_set('co_web_village', village)
    },
};

const actions = {
    setVillageLogo(value){

    },
    getVillageDetail(){
        return new Promise((resolve, reject)=>{
            villageDetails().then(res=>{
                state.villageId = res.data.village.id;
                state.village = res.data.village;
                if (res.data.identity !== null && (JSON.stringify(res.data.identity) !== '{}')) {
                    state.identity = res.data.identity;
                    state.isFamily = res.data.identity.is_family;
                    state.familyId = res.data.identity.family_id;
                    state.isJoin = false;
                    if(res.data.identity.state && res.data.identity.state_str){
                        state.isJoin = true;
                    }
                }
                resolve(res.data)
            }).catch(error=>{
                reject(error)
            })
        });

    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
