import api from './index'
// axios
import request from '../utils/request'

export function login(data) {
    return request({
        url: api.login,
        method: 'POST',
        data
    })
}

export function removeAccount(data) {
    return request({
        url: api.removeAccount,
        method: 'POST',
        data
    })
}
export function chooseLogin(data) {
    return request({
        url: api.chooseLogin,
        method: 'POST',
        data
    })
}

export function idcardLogin(data) {
    return request({
        url: api.idcardLogin,
        method: 'POST',
        data
    })
}

export function register(data) {
    return request({
        url: api.register,
        method: 'POST',
        data
    })
}

export function sendCode(data) {
    return request({
        url: api.send_ode,
        method: 'POST',
        data
    })
}


export function villageDetails(params) {
    return request({
        url: api.villages,
        method: 'GET',
        params
    })
}




export function getMuseumList(params) {
    return request({
        url: api.museum,
        method: 'GET',
        params
    })
}

export function getDeclarationShowStatus() {
    return request({
        url: api.declaration.status,
        method: 'GET',
    })
}

export function villageList(params) {
    return request({
        url: api.village_list,
        method: 'GET',
        params
    })
}
export function branch(params) {
    return request({
        url: api.branch,
        method: 'GET',
        params
    })
}
export function join(data) {
    return request({
        url: api.join,
        method: 'POST',
        data
    })
}


export function villagesImage(params){
    return request({
        url:api.villages_image,
        method: 'GET',
        params
    })
}
export function upload(data) {
    return request({
      url: api.upload,
      method: 'POST',
      timeout: 0,
      data
    })
  }